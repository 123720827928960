import React from 'react';
import './App.css';
import { HomePageComponent } from './pages/Home/HomePageComponent';

const App = () => {
  return (
      <HomePageComponent/>
  );
}


export default App;
