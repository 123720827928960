import React from "react";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute"
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";

export interface IBusCommuteCardProps {
    selectedRoute: IBusTrainRoute;
    startingStop: IBusTrainStop;
    endingStop: IBusTrainStop;
}

export class BusCommuteCard extends React.Component<IBusCommuteCardProps, {}> {
    public render() {
        return (
            <div className="card mt-2">
                <div className="row align-items-center">
                    <div className="col-sm-2">
                        <span className="badge badge-primary" style={{ fontSize: 14 }}>
                            {this.props.selectedRoute.shortName}
                        </span>
                    </div>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-12">
                                {this.props.startingStop.stopName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                to {this.props.endingStop.stopName}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}