import axios from "axios";
import { IDelayRequestDto } from "./interfaces/IDelayRequestDto";
import { IDelayRequestUpdateModel } from "./interfaces/IDelayRequestUpdateModel";
import { IDelayRequestCreateModel } from "./interfaces/IDelayRequestCreateModel";
import * as constants from "../constants";

export async function GetDelayRequestById(requestId: string){
    const url = `${constants.BaseApiUrl}/api/admin-delay-requests/?requestId=${requestId}`
    const response = await axios.get<IDelayRequestDto>(url);
    return response.data;
}

export async function UpdateDelayRequest(delayRequestUpdate: IDelayRequestUpdateModel){
    const url = `${constants.BaseApiUrl}/api/admin-delay-requests/Update`
    const response =await axios.post<IDelayRequestDto>(url,delayRequestUpdate);
    return response.data;
}

export async function AddDelayRequest(requestToAdd:IDelayRequestCreateModel) {
    const url = `${constants.BaseApiUrl}/api/public-delay-requests/Create`;
    var response = await axios.post<IDelayRequestDto>(url, requestToAdd);
    return response.data;
}