import React from "react";

export interface IErrorAlertProps {
    alertText: string;
}

export class ErrorAlert extends React.Component<IErrorAlertProps, {}> {

    public render() {
        return (
            <div className="alert alert-danger" role="alert">
               {this.props.alertText}
            </div>
        )
    }
}