import { IRequestFormState } from "./interfaces/IRequestFormState";
import { IRequestFormValidation } from "./interfaces/IRequestFormValidation";
import validator from "validator";
import moment from "moment";

export function validateRequestForm(state1: IRequestFormState) {
    const state = state1; // a way to prevent modifications to state
    let validationObj: IRequestFormValidation = {
        isFirstNameValid: state.firstName ? true : false,
        isLastNameValid: state.lastName ? true : false,
        isEmailAddressValid: false,
        isEmailAddressMatchWithConfirm: true,
        emailAddressValidationMessage: "",
        emailAddressConfirmValidationMessage: "",
        isDateOfServiceValid: false,
        isLateMinutesValid: false,
        isRouteDataValid: state.routeData.length > 0 ? true : false,
        isFormValid: false,
        isServiceValid: false,
        serviceDateValidation:"",
        isConfirmEmailAddressValid: false,
        isUserCommentsValid: true
    };
    if (state.dateOfService !== undefined) {
        const serviceDate = moment(state.dateOfService);
        const validDate = moment().add(-181, 'days');
        const currentDate = moment();
        if (serviceDate.isValid()) {

        if (validDate <= serviceDate && serviceDate <= currentDate) {
            validationObj.isDateOfServiceValid = true; 
        }
        else{
            validationObj.serviceDateValidation = "Delay must have been within the past 180 days";
        }
    } else {
        validationObj.serviceDateValidation = "Please enter valid Date";
    }
    }
    //Validate future trips
    if (state.dateOfService && state.routeData.length > 0){
        let serviceDate = state.dateOfService;
        let isDateChanged = false;
        const entryTime = moment(state.routeData[0].entryTime, ["h:mm A"]).format();
        let exitTime = moment(state.routeData[state.routeData.length - 1].exitTime, ["h:mm A"]).format();
        if (entryTime > exitTime){
            serviceDate = (serviceDate).add(1, 'day');
            isDateChanged = true;
        }
        let dateOfService = serviceDate.format("YYYY-MM-DD")
        exitTime = moment(state.routeData[state.routeData.length - 1].exitTime, ["h:mm A"]).format("HH:mm:ss");
        const serviceTime = moment(`${dateOfService} ${exitTime}`, 'YYYY-MM-DD HH:mm:ss').toISOString();
        if (serviceTime < moment().toISOString()){
            validationObj.isServiceValid = true;
        }
        if (isDateChanged){
            serviceDate = serviceDate.add(-1, 'day');
        }
    }
    // Validating Email Address
    if (state.emailAddress.toLowerCase() && validator.isEmail(state.emailAddress.toLowerCase())) {
        validationObj.isEmailAddressValid = true;
    } else {
        validationObj.isEmailAddressValid = false;
        validationObj.emailAddressValidationMessage ="Email address is not valid";
    }

    // Validating Confirm Email Address
    if (state.emailAddressConfirm.toLowerCase() && validator.isEmail(state.emailAddressConfirm.toLowerCase())) {
        validationObj.isConfirmEmailAddressValid = true;
    } else {
        validationObj.isConfirmEmailAddressValid = false;
        validationObj.emailAddressConfirmValidationMessage ="Email address is not valid";
    }

    if (validationObj.isConfirmEmailAddressValid &&
        state.emailAddress.toLowerCase() !== state.emailAddressConfirm.toLowerCase()) {
        validationObj.isEmailAddressMatchWithConfirm = false;
        validationObj.emailAddressConfirmValidationMessage ="Email addresses provided do not match"
    }

    // Valdating Lateness Duration
    if (state.lateMinutes && state.lateMinutes >= 10) {
        validationObj.isLateMinutesValid = true;
    } else {
        validationObj.isLateMinutesValid = false;
    }

    // Validating user comments
    if (state.delayComment.length > 1000) {
        validationObj.isUserCommentsValid = false;
    } else {
        validationObj.isUserCommentsValid = true;
    }


    validationObj.isFormValid =
        validationObj.isFirstNameValid &&
        validationObj.isLastNameValid &&
        validationObj.isEmailAddressValid &&
        validationObj.isEmailAddressMatchWithConfirm &&
        validationObj.isDateOfServiceValid &&
        validationObj.isLateMinutesValid &&
        validationObj.isRouteDataValid &&
        validationObj.isServiceValid &&
        validationObj.isUserCommentsValid


    return validationObj;

}

