import React from "react";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { IDelayRouteUi } from "../../interfaces/IDelayRouteUi";
import { ApiCallStatusEnum } from "../../../common/enums/ApiCallStatusEnum";
import * as BusStopsApi from "../../../../apis/BusStopsApi";
import * as ApiErrorHandler from "../../../../apis/ApiErrorHandler";
import { CircleSpinner } from "../../../common/CircleSpinner";
import { ErrorAlert } from "../../../common/ErrorAlert";

export interface IBusStopListLoaderProps {
    serviceDateStr: string;
    routeId: string;
    patternId: string;
    updateStopList: (stopList: IBusTrainStop[], nearByStopList: IBusTrainStop[]) => void;
    lastDelayRouteRec: IDelayRouteUi | null;
}

interface IBusStopListLoaderState {
    apiCallStatus: ApiCallStatusEnum;
    errorMessage: string;

}

export class BusStopListLoader extends React.Component<IBusStopListLoaderProps, IBusStopListLoaderState> {
    constructor(props: any) {
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.Started,
            errorMessage: ""
        };
    }

    public async componentDidMount() {
        try {
            this.setState({ apiCallStatus: ApiCallStatusEnum.Started, errorMessage: "" });
            const { routeId, serviceDateStr, patternId } = this.props;
            // we will need complete stop list for displaying end stop
            let stopList = await BusStopsApi.GetBusStops(routeId, patternId, serviceDateStr);
            let nearByStopList = [...stopList];

            if (this.props.lastDelayRouteRec) {
                nearByStopList = await BusStopsApi.GetNearbyBusStops(routeId, patternId, serviceDateStr,
                    this.props.lastDelayRouteRec.exitPointLon, this.props.lastDelayRouteRec.exitPointLat);
            }

            this.props.updateStopList(stopList, nearByStopList);


        } catch (error) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(error);
            this.setState({ apiCallStatus: ApiCallStatusEnum.Failed, errorMessage });
        }
    }

    public render() {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started || this.state.apiCallStatus === ApiCallStatusEnum.Success) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Failed) {
            return (
                <ErrorAlert alertText={this.state.errorMessage} />
            )
        } else {
            return (
                <ErrorAlert alertText="Unexpted Error. Please refresh the page and try again" />
            )
        }
    }


}