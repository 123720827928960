import React from "react";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import * as RouteIdHandler from "./RouteIdHandler";

export interface ITrainCommuteCardProps {
    selectedRoute: IBusTrainRoute;
    startingStop: IBusTrainStop;
    endingStop: IBusTrainStop;
}


export class TrainCommuteCard extends React.Component<ITrainCommuteCardProps, {}> {

    public render() {
        const routeId = RouteIdHandler.getRouteId(this.props.selectedRoute);
        const imgSrc = `lineIcons/${routeId}.svg`;
        return (
            <div className="card mt-2">
                <div className="row align-items-center">
                    <div className="col-sm-2">
                        <img className="ml-1" src={imgSrc} alt={this.props.selectedRoute.shortName} height="35" width="35" />
                    </div>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-12">
                                {this.props.startingStop.stopName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                to {this.props.endingStop.stopName}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}