import React from "react";

export interface ISuccessMessageProps {
    ReferenceNumber: string | undefined;
}

export class SuccessMessage extends React.Component<ISuccessMessageProps, {}> {
    public render() {
        return (<div>
            <h3 className="display-5 mt-4">Request Submitted Successfully</h3>
            <div className="mt-2 mb-2">Reference Number: {this.props.ReferenceNumber || ""} </div>
            <div>You will soon get an email confirming the receipt of this request. Please refresh the page to submit another request.</div>
        </div>)
    }
}