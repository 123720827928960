import React from "react";
import { IDelayRouteUi } from "../interfaces/IDelayRouteUi";
import { DelayRouteCard } from "./DelayRouteCard";

export interface IDelayRouteListProps {
    delayRouteList: IDelayRouteUi[];
    removeLastRouteLeg: ()=> void;
}

export class DelayRouteCardList extends React.Component<IDelayRouteListProps, {}> {

    public render() {

        const maxIndex = this.props.delayRouteList.length - 1;

        const cardList = this.props.delayRouteList.map((rec, index) => {
            // show delete button only on the last leg
            const showDeleteButton: boolean = (index === maxIndex);
            return (
                <div className="col-lg-12" key={index}>
                    <DelayRouteCard delayRouteObj={rec} showDeleteButton={showDeleteButton}
                        removeLastRouteLeg={this.props.removeLastRouteLeg}
                    />
                </div>
            )
        })

        return (
            <div className="row">
                {cardList}
            </div>
        )
    }
}