import axios from "axios";
import { IBusTrainRoute } from "./interfaces/IBusTrainRoute";
import * as constants from "../constants";

export async function GetBusRoutes(serviceDate: string){
    const url = `${constants.BaseApiUrl}/api/bus-routes/list?date=${serviceDate}`;
    const response = await axios.get<IBusTrainRoute[]>(url);
    return response.data;
}

export async function GetNearByBusRoutes(serviceDate: string, lon: number, lat: number){
    const url =  `${constants.BaseApiUrl}/api/bus-routes/list-nearby?date=${serviceDate}&lon=${lon}&lat=${lat}`;
    const response = await axios.get<IBusTrainRoute[]>(url);
    return response.data;
}