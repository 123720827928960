import React from "react";
import { IRequestFormState } from "./interfaces/IRequestFormState";
import * as RequestFormValidator from "./RequestFormValidator";
import { FormInputControl } from "../common/FormInputControl";
import { FormTextAreaControl } from "../common/FormTextAreaControl";
import { RouteAddCard } from "./RouteAddCard";
import { IDelayRouteUi } from "./interfaces/IDelayRouteUi";
import { DelayRouteCardList } from "./DelayRouteList/DelayRouteCardList";
import * as DelayRequestMapper from "./Mappers/DelayRequestMapper";
import * as DelayRequestApi from "../../apis/DealyRequestApi";
import { ErrorAlert } from "../common/ErrorAlert";
import { CircleSpinner } from "../common/CircleSpinner";
import { SuccessMessage } from "./SuccessMessage";
import { ServiceDatePickerControl } from "./Controls/ServiceDatePickerControl";
import * as ApiErrorHandler from "../../apis/ApiErrorHandler";
import packageJson from "../../../package.json";
import { FormNumberControl } from "../common/FormNumberControl";


export class RequestForm extends React.Component<{}, IRequestFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            emailAddress: "",
            emailAddressConfirm: "",
            dateOfService: undefined,
            lateMinutes: undefined,
            delayComment: "",
            routeData: [],
            isTriedToSubmit: false,
            isFirstNameValid: false,
            isLastNameValid: false,
            isEmailAddressValid: false,
            isEmailAddressMatchWithConfirm: false,
            emailAddressValidationMessage: "",
            emailAddressConfirmValidationMessage: "",
            isDateOfServiceValid: false,
            isLateMinutesValid: false,
            isRouteDataValid: false,
            isFormValid: false,
            saveApiCallStarted: false,
            saveApiCallSuccess: false,
            saveApiCallError: false,
            saveErrorMessage: "",
            disableDateOfService: false,
            referenceNum: undefined,
            isServiceValid: false,
            serviceDateValidation: "",
            isEmailValid: true,
            isConfirmEmailValid: true,
            isServiceDateValid: true,
            isDelayValid: true,
            isConfirmEmailAddressValid: false,
            isUserCommentsValid: true,
        };
    }

    public render() {
        const versionStyle = {
            display: 'none'
        };
        if (this.state.saveApiCallStarted) {
            return (
                <CircleSpinner />
            )
        } if (this.state.saveApiCallSuccess) {
            return (<SuccessMessage ReferenceNumber={this.state.referenceNum} />)
        } else {
            const reportUrl = `https://contact.mta.info/s/customer-feedback`;
            return (
                <div >
                    <span>
                        <h6 className="card-title">This service provides verification of delays to trips using our subways and buses, for delays longer than 10 minutes that occurred within the last 180 days. If you are seeking delay verification for travel more than 180 days ago, please call our Customer Services team by dialing 511.</h6>
                        <h6 className="card-title">To request delay verification, complete this form with the details of the trip you took, including each bus or subway train you traveled on. Enter the times of each leg of your trip as best as you can recall. <b> If you took a different, longer route because your normal journey was experiencing delay, complete this form with the details of your normal journey.</b></h6>
                        <h6 className="card-title">You will receive an email acknowledging your submission. After your request is researched, you will receive a second email with our findings – this is typically within 36 hours but may take up to 3 business days.</h6>
                        <h6 className="card-title">While we make every effort to run our trains and buses to schedule, delays occur for a variety of reasons, including sick passengers, police investigations, scheduled and unscheduled maintenance, and other factors. We thank you for your patience and for riding with MTA New York City Transit.</h6>
                        <h6 className="card-title"><a href ={reportUrl}>Click here</a> to tell us about any bugs or problems you experience trying to submit your request.</h6>
                    </span>
                    {this.state.saveApiCallError && <ErrorAlert alertText={this.state.saveErrorMessage} />}
                    <form>
                        <div className="row">
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="text"
                                    placeHolder=""
                                    label="First Name"
                                    changeHandler={this.handleInputChange("firstName")}
                                    value={this.state.firstName}
                                    isValid={!this.state.isTriedToSubmit || this.state.isFirstNameValid}
                                    autoCompleteHint="given-name"
                                />
                            </div>
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="text"
                                    placeHolder=""
                                    label="Last Name"
                                    changeHandler={this.handleInputChange("lastName")}
                                    value={this.state.lastName}
                                    isValid={!this.state.isTriedToSubmit || this.state.isLastNameValid}
                                    autoCompleteHint="family-name"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="email"
                                    placeHolder=""
                                    label="Email"
                                    changeHandler={this.handleInputChange("emailAddress")}
                                    value={this.state.emailAddress}
                                    isValid={this.state.isEmailValid}
                                    validationMessage = {this.state.emailAddressValidationMessage}
                                    autoCompleteHint="email"
                                />
                            </div>
                            <div className="col-lg-6">
                                <FormInputControl
                                    type="email"
                                    placeHolder=""
                                    label="Confirm Email"
                                    changeHandler={this.handleInputChange("emailAddressConfirm")}
                                    value={this.state.emailAddressConfirm}
                                    isValid={this.state.isConfirmEmailValid}
                                    validationMessage={this.state.emailAddressConfirmValidationMessage}
                                    autoCompleteHint="email"
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <ServiceDatePickerControl
                                    label="Date of Service"
                                    value={this.state.dateOfService}
                                    changeHandler={this.handleInputChange("dateOfService")}
                                    isValid={this.state.isServiceDateValid}
                                    disablePicker={this.state.disableDateOfService}
                                    disabledMessage="Cannot change this field once journey legs have been added"
                                    selectablePastDays={181}
                                    validationMessage = {this.state.serviceDateValidation}
                                />
                            </div>
                            <div className="col-lg-6">
                                <FormNumberControl
                                    type="number"
                                    placeHolder=""
                                    label="Total minutes delayed (minimum: 10 mins.)"
                                    changeHandler={this.handleInputChange("lateMinutes")}
                                    value={this.state.lateMinutes+""}
                                    validationMessage="Delay must be 10 minutes or higher"
                                    isValid={this.state.isDelayValid}
                                />
                            </div>
                        </div>
                    </form >
                    <DelayRouteCardList delayRouteList={this.state.routeData}
                        removeLastRouteLeg={this.removeLastRouteLeg} />
                    <div className="row mt-2">
                        <div className="col-lg-6">
                            <RouteAddCard IsAddButtonsEnabled={this.state.isDateOfServiceValid}
                                dateofService={this.state.dateOfService}
                                addToDelayRouteList={this.addToDelayRouteList}
                                isRouteDataValid={!this.state.isTriedToSubmit || this.state.isRouteDataValid}
                                lastDelayRouteRec={this.getLastDelayRoutedRec()}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                            <div className="col-lg-12">
                                <FormTextAreaControl
                                    label="Trip Comments"
                                    rowcount={3}
                                    changeHandler={this.handleInputChange("delayComment")}
                                    value={this.state.delayComment}
                                    isValid={this.state.isUserCommentsValid}
                                    validationMessage="Maximum 1000 characters allowed"
                                />
                            </div>
                        </div>

                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={this.submitDelayRequest}
                            >Submit</button>
                        </div>
                    </div>
                    <br></br>
                    <div style={versionStyle}>
                        Version {packageJson.version}
                    </div>
                </div>

            )
        }
    }

    private handleInputChange = (fieldName: string) => (value: any) => {
        if (fieldName === "emailAddress") {
            this.setState({
                isEmailValid: false
            });
        } else if (fieldName === "emailAddressConfirm") {
            this.setState({
                isConfirmEmailValid: false
            });
        } else if (fieldName === "dateOfService") {
            this.setState({
                isServiceDateValid: false
            });
        } else if (fieldName === "lateMinutes") {
            this.setState({
                isDelayValid: false
            });
        } else if (fieldName === "delayComment") {
            this.setState({
                isUserCommentsValid: false
            });
        }
        this.setState({
            [fieldName]: value
        } as any, this.validateFields);
    }

    private validateFields = () => {
        const validationObj = RequestFormValidator.validateRequestForm(this.state);
        this.setState(validationObj);
        if (!this.state.isEmailValid) {
            this.setState({
                isEmailValid: validationObj.isEmailAddressValid
            });
        }
        if (!this.state.isConfirmEmailValid) {
            if (validationObj.isEmailAddressMatchWithConfirm && validationObj.isConfirmEmailAddressValid) {
                this.setState({
                    isConfirmEmailValid: validationObj.isConfirmEmailAddressValid
                });
            }
        }
        if (!this.state.isServiceDateValid) {
            this.setState({
                isServiceDateValid: validationObj.isDateOfServiceValid
            });
        }
        if (!this.state.isDelayValid) {
            this.setState({
                isDelayValid: validationObj.isLateMinutesValid
            });
        }
        if (!this.state.isUserCommentsValid) {
            this.setState({
                isUserCommentsValid: validationObj.isUserCommentsValid
            });
        }
    }

    private addToDelayRouteList = (newTravelRoute: IDelayRouteUi) => {
        this.setState((currentState) => {
            var routeList = [...currentState.routeData];
            newTravelRoute.legNumber = routeList.length + 1;
            routeList.push(newTravelRoute);
            return { routeData: routeList, isRouteDataValid: true, disableDateOfService: true }
        })
    }

    private submitDelayRequest = async () => {
        // Cannot call validateFields() here, due to the asyc nature of setState..
        const validationObj = RequestFormValidator.validateRequestForm(this.state);
        this.setState(validationObj);
        this.setState({
            isEmailValid: validationObj.isEmailAddressValid,
            isServiceDateValid: validationObj.isDateOfServiceValid,
            isDelayValid: validationObj.isLateMinutesValid
        });
        if (validationObj.isConfirmEmailAddressValid && validationObj.isEmailAddressMatchWithConfirm) {
            this.setState({
                isConfirmEmailValid: validationObj.isConfirmEmailAddressValid
            });
        } else {
            this.setState({
                isConfirmEmailValid: false
            })
        }

        if (!validationObj.isFormValid) {
            this.setState({ isTriedToSubmit: true });
            if (!validationObj.isServiceValid && validationObj.isDateOfServiceValid && validationObj.isRouteDataValid){
                this.setState({
                    saveApiCallError: true, saveErrorMessage: "Your delay verification request cannot be submitted for a trip in the future"
                })
            } else if (validationObj.isServiceValid && validationObj.isDateOfServiceValid && validationObj.isRouteDataValid){
                this.setState({
                    saveApiCallError: false, saveErrorMessage: ''
                })
            }
        } else {
            try {
                this.setState({ saveApiCallStarted: true, saveApiCallSuccess: false, saveApiCallError: false, saveErrorMessage: "" });
                var requestToSave = DelayRequestMapper.MapToDelayRequest(this.state);
                var addrequest = await DelayRequestApi.AddDelayRequest(requestToSave);
                this.setState({ saveApiCallStarted: false, saveApiCallSuccess: true, saveApiCallError: false, saveErrorMessage: "", referenceNum: addrequest.referenceNumber });
            } catch (err) {
                const errMsg = ApiErrorHandler.GetApiErrorMessage(err);
                this.setState({ saveApiCallStarted: false, saveApiCallSuccess: false, saveApiCallError: true, saveErrorMessage: errMsg });
            }
        }
    }

    private getLastDelayRoutedRec = () => {
        const routeDateLen = this.state.routeData.length;
        if (routeDateLen > 0) {
            return this.state.routeData[routeDateLen - 1];
        }
        return null;
    }

    private removeLastRouteLeg = () => {
        const newRouteList: IDelayRouteUi[] = [];
        for (let i = 0; i < this.state.routeData.length - 1; i++) {
            newRouteList.push(this.state.routeData[i]);
        }
        this.setState({ routeData: newRouteList });
        if (this.state.routeData.length <= 1){
            this.setState({
                disableDateOfService: false,
                saveApiCallError: false,
                saveErrorMessage: ''
            });
        }
    }

}