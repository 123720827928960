import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

export interface ITimePickerControlProps {
    label: string;
    value: moment.Moment | undefined;
    changeHandler: (value: any) => void;
    isValid: boolean;
    validationMessage?: string;
    disablePicker?: boolean;
    disabledMessage?: string;
}

export interface ITimePickerControlState {
    selectedValue: moment.Moment | string | undefined;
}

export class TimePickerControl extends React.Component<ITimePickerControlProps, ITimePickerControlState> {

    constructor(props: ITimePickerControlProps) {
        super(props);
        this.state = {
            selectedValue: undefined
        }
    }

    public componentDidMount() {
        this.setState((state, props) => {
            if (props.value) {
                return { selectedValue: moment(props.value) }
            } else {
                return { selectedValue: undefined }
            }
        })
    }

    public render() {
        return (
            <div className="form-group">
                <label >{this.props.label}</label>
                <Datetime
                    value={this.state.selectedValue}
                    onChange={this.handleDateChange}
                    onBlur={this.focousOut}
                    inputProps={{type:'time', className: this.getInputClassName(), disabled: this.props.disablePicker || false }}
                    locale='en-US'
                    dateFormat={false}
                    timeFormat={true}
                
                />
                {this.renderValidationMessage()}
                {this.props.disablePicker && this.props.disabledMessage && <small id="emailHelp" className="form-text text-muted">{this.props.disabledMessage || ""}</small> }
            </div>
        )
    }

    private renderValidationMessage = () =>{
        if(this.props.validationMessage && !this.props.isValid){
            return (
                <div style={{ marginTop: "0.25rem", fontSize: "80%", color: "#dc3545" }} > {this.props.validationMessage} </div>
            );
        } else if(this.props.validationMessage){
            return (
                <div style={{ marginTop: "0.25rem", fontSize: "80%" }} className="text-muted" > {this.props.validationMessage} </div>
            ); 
        }

    }

    private focousOut = (value: any) => {
        if (typeof value !== "string") {
            this.setState({ selectedValue: value });
        }
    }

    // Datetime control returns string if the date entered by user is not valid 
    private handleDateChange = (dateVal: moment.Moment | string) => {
        this.setState({ selectedValue: dateVal})
        if (typeof dateVal === "string") {
            if (dateVal.length === 2){
                if (dateVal.indexOf(':') !== 1){
                    this.setState({ selectedValue: dateVal.concat(':')})
                }
            }
            var dateUpdate = moment(dateVal, 'HH:mm', true)
            if (dateUpdate.isValid()){
                this.props.changeHandler(dateUpdate)
            } else {
                var index = dateVal.indexOf(':')
                if (index !== -1 && dateVal.length === index + 3){
                    this.setState({ selectedValue: dateVal.concat(' ')})
                }
                this.props.changeHandler(undefined)
            }
        } else if (typeof dateVal) {
            this.props.changeHandler(dateVal)
        } else {
            this.props.changeHandler(undefined);
        }
    }

    private getInputClassName = () => {
        if (this.props.isValid) {
            return "form-control";
        } else {
            return "form-control is-invalid";
        }
    }

    

}