import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBusPatternDto } from "../../../../apis/interfaces/IBusPatternDto";
import { ApiCallStatusEnum } from "../../../common/enums/ApiCallStatusEnum";
import * as ApiErrorHandler from "../../../../apis/ApiErrorHandler";
import * as BusPatternsApi from "../../../../apis/BusPatternsApi";
import { CircleSpinner } from "../../../common/CircleSpinner";
import { ErrorAlert } from "../../../common/ErrorAlert";

export interface IBusPatternSelectorProps {
    selectedRouteId: string;
    serviceDateStr: string;
    updateSelectedPattern: (selectedPattern: IBusPatternDto) => void;

}

interface IBusPatternSelectorState {
    apiCallStatus: ApiCallStatusEnum,
    patternList: IBusPatternDto[],
    errorMessage: string;
}

export class BusPatternSelector extends React.Component<IBusPatternSelectorProps, IBusPatternSelectorState> {
    constructor(props: any) {
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.Started,
            patternList: [],
            errorMessage: ""
        };
    }

    public async componentDidMount() {
        try {
            this.setState({ apiCallStatus: ApiCallStatusEnum.Started, errorMessage: "" });
            const patternList = await BusPatternsApi.GetBusPatterns(this.props.selectedRouteId, this.props.serviceDateStr);
            this.setState({ patternList });
            this.setState({ apiCallStatus: ApiCallStatusEnum.Success });
        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ apiCallStatus: ApiCallStatusEnum.Failed, errorMessage });
        }

    }

    public render() {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            );
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Success) {
            return this.getPatternCardList();
        } else {
            return (
                <ErrorAlert alertText={this.state.errorMessage} />
            )
        }
    }

    private getPatternCardList = () => {
        const patternCards = this.state.patternList
            .map((rec, index) => {
                return (
                    <div className="busstop_route_div_button mt-2" key={index}
                        onClick={(event) => { this.props.updateSelectedPattern(rec) }}                       >
                        <div className="row no-gutters">
                            <div className="col-sm-10">
                               TO {rec.description}
                            </div>
                            <div className="col-sm-1">
                                <span className="float-right"><FontAwesomeIcon size="1x" icon={faAngleRight} /></span>
                            </div>
                        </div>
                    </div>
                );
            });

        return (
            <div>
                {patternCards}
            </div>
        )
    }
}