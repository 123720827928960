import React from "react";
import { IDelayRouteUi } from "../interfaces/IDelayRouteUi";
import { TransitTypeEnum } from "../../../apis/enums/TransitTypeEnum";

export interface IDelayRouteCardProps {
    delayRouteObj: IDelayRouteUi;
    showDeleteButton: boolean;
    removeLastRouteLeg: () => void;
}

export class DelayRouteCard extends React.Component<IDelayRouteCardProps, {}> {

    public render() {
        return (
            <div className="card mt-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-1">
                            {this.props.delayRouteObj.legNumber}
                        </div>
                        <div className="col-sm-2">
                            {this.getRouteIcon()}
                        </div>
                        <div className="col-sm-9">
                            <div className="row">
                                <div className="col-sm-12">
                                   {this.getLegDescription()}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.showDeleteButton && <div className="row">
                        <div className="col-sm-12">
                            <button type="button"
                                className="btn btn-outline-dark  btn-sm float-right"
                                onClick={this.props.removeLastRouteLeg}
                            >Remove</button>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }

    private getLegDescription = () => {
        if (this.props.delayRouteObj.routeType === TransitTypeEnum.SIFerry) {
            return (<div>
                {this.props.delayRouteObj.entryPointName} to {this.props.delayRouteObj.exitPointName}
            </div>);
        } else {
            return (
                <div>
                    {this.props.delayRouteObj.entryPointName} (<b>{this.props.delayRouteObj.entryTime}</b>) to {this.props.delayRouteObj.exitPointName} (<b>{this.props.delayRouteObj.exitTime}</b>)
                </div>
            )
        }
    }

    private getRouteIcon = () => {

        const delayRouteObj = this.props.delayRouteObj;
        if (delayRouteObj.routeType === TransitTypeEnum.Subway) {
            const imgSrc = `lineIcons/${delayRouteObj.routeName}.svg`;
            return (<img src={imgSrc} style={{ marginRight: 5 }} alt={delayRouteObj.routeName} height="25" width="25" />)
        } else {
            return <span className="badge badge-primary" style={{ fontSize: 15 }}>
                {delayRouteObj.routeName}
            </span>
        }

    }



}