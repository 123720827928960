import React from "react";
import { IDelayRouteUi } from "../../interfaces/IDelayRouteUi";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ScreenTypeEnum } from "../enums/ScreenTypeEnum";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { BusRouteSelector } from "./BusRouteSelector";
import { IBusPatternDto } from "../../../../apis/interfaces/IBusPatternDto";
import { BusPatternSelector } from "./BusPatternSelector";
import { BusStopListLoader } from "./BusStopListLoader";
import { StopSelector } from "../CommonDelaySelect/StopSelector";
import { TransitTypeEnum } from "../../../../apis/enums/TransitTypeEnum";
import { TimeEntryForm } from "../CommonDelaySelect/TimeEntryForm";

export interface IBusDelaySelectModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    serviceDateStr: string;
    addToDelayRouteList: (newTravelRoute: IDelayRouteUi) => void;
    lastDelayRouteRec: IDelayRouteUi | null;
}

export interface IBusDelaySelectModalState {
    screenType: ScreenTypeEnum,
    selectedRoute: IBusTrainRoute | null;
    selectedPattern: IBusPatternDto | null;
    stopList: IBusTrainStop[];
    nearbyStopList: IBusTrainStop[];
    startingStop: IBusTrainStop | null;
    endingStop: IBusTrainStop | null;
}

export class BusDelaySelectModal extends React.Component<IBusDelaySelectModalProps, IBusDelaySelectModalState> {

    constructor(props: any) {
        super(props);
        const initiState = this.getInitialState();
        this.state = { ...initiState }
    }

    public render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={this.props.toggleModal} scrollable={true}
                onOpened={this.initState} backdrop="static">
                <ModalHeader toggle={this.props.toggleModal}>{this.getModalHeaderText()}</ModalHeader>
                <ModalBody>
                    {this.getModalBody()}
                </ModalBody>
            </Modal>
        )
    }

    private getModalBody = () => {
        if (this.state.screenType === ScreenTypeEnum.ShowRoutes) {
            return (
                <BusRouteSelector serviceDateStr={this.props.serviceDateStr}
                    updateSelectedBusRoute={this.updateSelectedRoute}
                    lastDelayRouteRec={this.props.lastDelayRouteRec}
                />
            )
        } else if (this.state.screenType === ScreenTypeEnum.ShowDirection && this.state.selectedRoute) {
            return (
                <BusPatternSelector
                    selectedRouteId={this.state.selectedRoute.routeId}
                    serviceDateStr={this.props.serviceDateStr}
                    updateSelectedPattern={this.updateSelectedPattern}
                />
            )

        } else if (this.state.screenType === ScreenTypeEnum.showStopListLoader && this.state.selectedRoute
            && this.state.selectedPattern) {
            return (
                <BusStopListLoader
                    routeId={this.state.selectedRoute.routeId}
                    serviceDateStr={this.props.serviceDateStr}
                    patternId={this.state.selectedPattern.patternId}
                    updateStopList={this.updateStopList}
                    lastDelayRouteRec={this.props.lastDelayRouteRec}
                />
            );
        } else if (this.state.screenType === ScreenTypeEnum.showStartStopList) {
            return (
                <StopSelector stopList={this.state.nearbyStopList} 
                    updateSelectedStop={this.updateStartingStop}
                    stopCardBorderColor="#0f61a9"
                    transitType={TransitTypeEnum.Bus}
                />
            )
        } else if (this.state.screenType === ScreenTypeEnum.showEndStopList && this.state.startingStop) {
            return (
                <StopSelector stopList={this.state.stopList}
                    alreadySelectedStop={this.state.startingStop}
                    updateSelectedStop={this.updateEndingBusStop}
                    stopCardBorderColor="#0f61a9"
                    transitType={TransitTypeEnum.Bus}
                />
            )
        }else if (this.state.screenType === ScreenTypeEnum.showTimeEntry && this.state.selectedRoute
            && this.state.selectedPattern && this.state.startingStop
            && this.state.endingStop) {
            return (
                <TimeEntryForm selectedRoute={this.state.selectedRoute}
                    startingStop={this.state.startingStop}
                    endingStop={this.state.endingStop}
                    selectedPatternId ={this.state.selectedPattern.patternId}
                    addToDelayRouteList={this.props.addToDelayRouteList}
                    transitType={TransitTypeEnum.Bus}
                    lastDelayRouteRec = {this.props.lastDelayRouteRec}
                />
            )
        }
        return <></>
    }

    private initState = () => {
        const initState = this.getInitialState();
        this.setState({ ...initState })
    }

    private getInitialState = () => {
        const initState: IBusDelaySelectModalState = {
            screenType: ScreenTypeEnum.ShowRoutes,
            selectedRoute: null,
            selectedPattern: null,
            stopList: [],
            nearbyStopList: [],
            startingStop: null,
            endingStop: null,
        }

        return initState;
    }

    private updateSelectedRoute = (selectedRoute: IBusTrainRoute) => {
        const selRouteCopy = { ...selectedRoute };
        this.setState({
            selectedRoute: selRouteCopy,
            screenType: ScreenTypeEnum.ShowDirection,
            stopList: [],
            nearbyStopList: [],
            startingStop: null,
            endingStop: null,
        });
    }

    private updateSelectedPattern = (selectedPattern: IBusPatternDto) => {
        const patternCopy = { ...selectedPattern };
        this.setState({
            selectedPattern: patternCopy,
            screenType: ScreenTypeEnum.showStopListLoader,
            stopList: [],
            nearbyStopList: [],
            startingStop: null,
            endingStop: null,
        })
    }

    private updateStopList = (stopList: IBusTrainStop[], nearbyStopList: IBusTrainStop[]) => {
        this.setState({
            screenType: ScreenTypeEnum.showStartStopList,
            stopList: stopList,
            nearbyStopList: nearbyStopList,
            startingStop: null,
            endingStop: null,
        });
    }

    private updateStartingStop = (startStop: IBusTrainStop) => {
        const startStopCopy = { ...startStop };
        this.setState({
            screenType: ScreenTypeEnum.showEndStopList,
            startingStop: startStopCopy,
            endingStop: null,
        });
    }

    private updateEndingBusStop = (endingStop: IBusTrainStop) => {
        const endingBusCopy = { ...endingStop };
        this.setState({
            screenType: ScreenTypeEnum.showTimeEntry,
            endingStop: endingBusCopy,
        });
    }

    private getModalHeaderText = () => {
        if (this.state.screenType === ScreenTypeEnum.ShowRoutes) {
            return (<div><h6>Select a Bus Route (1 of 5)</h6></div>);
        } else if (this.state.screenType === ScreenTypeEnum.ShowDirection) {
            return (
                <div><h6>Choose the direction for {this.getBusIcon()} (2 of 5)</h6></div>
            )
        } else if (this.state.screenType === ScreenTypeEnum.showStopListLoader ||
            this.state.screenType === ScreenTypeEnum.showStartStopList) {
            return (
                <div><h6>where did you <b>board</b> {this.getBusIcon()} ? (3 of 5)</h6></div>
            )
        } else if (this.state.screenType === ScreenTypeEnum.showEndStopList) {
            return (
                <div><h6>where did you <b>exit</b> {this.getBusIcon()} ? (4 of 5)</h6></div>
            )
        } else if (this.state.screenType === ScreenTypeEnum.showTimeEntry) {
            return (
                <div><h6>Enter times for {this.getBusIcon()} (5 of 5)</h6></div>
            )
        }
        return <></>;
    }

    private getBusIcon = () => {
        if (this.state.selectedRoute) {
            return (
                <span className="badge badge-primary" >
                    {this.state.selectedRoute.shortName}
                </span>
            )
        } else {
            return <></>;
        }
    }
}