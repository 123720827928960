import React from "react";
import moment from "moment";
import { IDelayRouteUi } from "./interfaces/IDelayRouteUi";
import { BusDelaySelectModal } from "./TransferLegSel/BusDelaySelect/BusDelaySelectModal";
import { TrainDelaySelectModal } from "./TransferLegSel/TrainDelaySelect/TrainDelaySelectModal";
import * as SIFerryHandler from "./SIFerry/SIFerryHandler";
import { TransitTypeEnum } from "../../apis/enums/TransitTypeEnum";

export interface IRouteAddCardProps {
    IsAddButtonsEnabled: boolean;
    dateofService: moment.Moment | undefined;
    addToDelayRouteList: (newTravelRoute: IDelayRouteUi) => void;
    isRouteDataValid: boolean;
    lastDelayRouteRec: IDelayRouteUi | null;
}

export interface IRouteAddCardState {
    isDelayRouteModalOpen: boolean;
    isBusDelaySelectModalOpen: boolean;
}

export class RouteAddCard extends React.Component<IRouteAddCardProps, IRouteAddCardState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isDelayRouteModalOpen: false,
            isBusDelaySelectModalOpen: false,
        }
    }


    public render() {
        const serivceDateStr = this.getSerivceDateString();
        return (
            <div>
                <div className={this.getCardClass()}>
                    <div className="card-body">
                        <p className="card-text">Tell us which bus and/or subway routes you used (or would have used) in your journey. If more than one, add them in order, from first to last.</p>
                        <div className="row">
                            <div className="col-lg-4">
                                <button type="button" className="btn btn-secondary mt-1" disabled={!this.props.IsAddButtonsEnabled}
                                    onClick={this.toggleBusDelaySelectModal}
                                >Add Bus Travel</button>
                            </div>
                            <div className="col-lg-5">
                                <button type="button" className="btn btn-secondary mt-1" disabled={!this.props.IsAddButtonsEnabled}
                                    onClick={this.toggleTrainDelayRouteModal}
                                >Add Subway Travel</button>
                            </div>
                            {this.renderSiFerryButton()}
                        </div>



                        {!this.props.IsAddButtonsEnabled && <small className="form-text text-muted">Please select Date of Service before adding travel </small>}
                    </div>
                </div>
                <TrainDelaySelectModal toggleModal={this.toggleTrainDelayRouteModal}
                    isModalOpen={this.state.isDelayRouteModalOpen}
                    serviceDateStr={serivceDateStr}
                    addToDelayRouteList={this.addTrainToDelayRouteList}
                    lastDelayRouteRec={this.props.lastDelayRouteRec}
                />
                <BusDelaySelectModal
                    toggleModal={this.toggleBusDelaySelectModal}
                    isModalOpen={this.state.isBusDelaySelectModalOpen}
                    serviceDateStr={serivceDateStr}
                    addToDelayRouteList={this.addBusToDelayRouteList}
                    lastDelayRouteRec={this.props.lastDelayRouteRec}
                />


            </div>
        )
    }

    private toggleBusDelaySelectModal = () => {
        this.setState((state) => {
            return { isBusDelaySelectModalOpen: !state.isBusDelaySelectModalOpen };
        });
    }


    private getSerivceDateString = () => {
        if (this.props.dateofService) {
            const serviceDateStr = this.props.dateofService.format("MM/DD/YYYY");
            return serviceDateStr;
        }
        return "";
    }

    private toggleTrainDelayRouteModal = () => {
        this.setState((state) => {
            return { isDelayRouteModalOpen: !state.isDelayRouteModalOpen }
        })
    }

    private addBusToDelayRouteList = (newTravelRoute: IDelayRouteUi) => {
        this.toggleBusDelaySelectModal();
        this.props.addToDelayRouteList(newTravelRoute);
    }

    private addTrainToDelayRouteList = (newTravelRoute: IDelayRouteUi) => {
        this.toggleTrainDelayRouteModal();
        this.props.addToDelayRouteList(newTravelRoute);
    }

    private getCardClass = () => {
        if (this.props.isRouteDataValid) {
            return "card";
        } else {
            return "card border-danger";
        }
    }

    private renderSiFerryButton = () => {
        if (this.props.lastDelayRouteRec && this.props.lastDelayRouteRec.routeId !== 'SIFerry') {
            if (SIFerryHandler.IsNearyBySIFerry(this.props.lastDelayRouteRec.exitPointLat,
                this.props.lastDelayRouteRec.exitPointLon)) {
                return (
                    <div className="col-lg-4">
                        <button type="button" className="btn btn-secondary mt-1" disabled={!this.props.IsAddButtonsEnabled}
                        onClick={this.onSiFerryAddHandler}
                        >Add SI Ferry</button>
                    </div>
                );
            }
        }
        return <></>;
    }


    private onSiFerryAddHandler = ()=> {
        if(this.props.lastDelayRouteRec){
            if(this.props.lastDelayRouteRec.routeType !== TransitTypeEnum.SIFerry){
                const delayRouteObj= SIFerryHandler.getSiFerryDelayRouteObj(this.props.lastDelayRouteRec.exitPointLat,
                    this.props.lastDelayRouteRec.exitPointLon, this.props.lastDelayRouteRec.exitTime,
                    this.props.lastDelayRouteRec.exitHour, this.props.lastDelayRouteRec.exitMinute);
                this.props.addToDelayRouteList(delayRouteObj);
            }
        }
    }
}