import React from "react";
import { Navbar, NavbarToggler } from "reactstrap";


interface INavbarState {
    isOpen: boolean;
}

export class NavbarComponent extends React.Component<{}, INavbarState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false
        };
    }
    public render() {
        return (
            <Navbar className="navbar-dark bg-dark" expand="md">
                <div className="container">
                    <a className="navbar-brand" href="https://new.mta.info/">
                        <img src="logo.svg" alt="MTA" width="30" height="30" className="mr-2" />
                        <a className="navbar-brand" href= "https://delayverification.mta.info/">
                            Delay Verification
                        </a>
                    </a>
                </div>
            </Navbar>
        )
    }
}