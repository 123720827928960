import React from "react";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import { BusRouteCard } from "./BusRouteCard";

export interface IBusRouteCardListProps {
    busRouteList: IBusTrainRoute[];
    updateSelectedBusRoute: (selectedRoute: IBusTrainRoute) => void;
}

export class BusRouteCardList extends React.Component<IBusRouteCardListProps, {}> {
    
    public render(){
        return (
            <div>
                {this.getRouteList()}
            </div>
        )
    }
    
    private getRouteList = ()=> {
        const busRoutes = this.props.busRouteList;
        
        if(busRoutes){
            const routeList = busRoutes.map((rec, index)=>{
                return (
                    <div key={index}><BusRouteCard busRouteObj={rec}
                        updateSelectedBusRoute={this.props.updateSelectedBusRoute}
                    /></div>
                )
            });
            return <div>
                {routeList}
            </div>
        }
        return <></>
    }
}