import React from "react";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import * as RouteIdHandler from "./RouteIdHandler";

export interface ITrainRouteIconProps {
    trainRouteObj: IBusTrainRoute;
    updateSelectedTrainRoute: (selectedRoute: IBusTrainRoute) => void;
}

export class TrainRouteIcon extends React.Component<ITrainRouteIconProps, {}> {

    public render() {
        const routeId = RouteIdHandler.getRouteId(this.props.trainRouteObj);
        const imgSrc = `lineIcons/${routeId}.svg`;
        return (<div className="mt-2">
            <button type="button" className="btn btn-outline-light"
                onClick ={()=> this.props.updateSelectedTrainRoute(this.props.trainRouteObj)}
            >
               <img src={imgSrc}  alt={this.props.trainRouteObj.shortName} height="35" width="35" />
            </button>
        </div>)
    }
  
}