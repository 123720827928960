export function GetApiErrorMessage(errobj: any) {
    if (errobj?.response?.data) {
        if (errobj.response.data.apiError?.message) {
            return errobj.response.data.apiError?.message;
        }
        if (errobj.response.data.errors) {
            return JSON.stringify(errobj.response.data.errors);
        }
        return JSON.stringify(errobj.response.data);
    } else if (errobj?.message) {
        return errobj.message + " - There was an error performing your request";
    } else {
        return "There was an error performing your request";
    }
}