import { IBusTrainStop } from "../../../apis/interfaces/IBusTrainStop";
import tfdistance from "@turf/distance";
import {
    point as tfpoint} from "@turf/helpers";
import { TransitTypeEnum } from "../../../apis/enums/TransitTypeEnum";
import { IDelayRouteUi } from "../interfaces/IDelayRouteUi";

const WhiteHallTerminal:IBusTrainStop = {
    stopId: "1",
    stopName: "Whitehall Terminal",
    otherRoutes: "",
    stopSequence: 1,
    latitude: 40.701409,
    longitude: -74.013131
};

const StGeorgeTerminal: IBusTrainStop = {
    stopId: "2",
    stopName: "St. George Terminal",
    otherRoutes: "",
    stopSequence: 2,
    latitude: 40.643333,
    longitude: -74.074167
};

const whiteHallPoint = tfpoint([WhiteHallTerminal.longitude, WhiteHallTerminal.latitude]);
const stGeorgePoint = tfpoint([StGeorgeTerminal.longitude, StGeorgeTerminal.latitude]);

export function IsNearyBySIFerry(latitude: number, longitude: number){
    const pointToCheck = tfpoint([longitude, latitude]);
    
    const distanceToWhiteHall = tfdistance(pointToCheck, whiteHallPoint,"miles");
    const stGeorgeDistance = tfdistance(pointToCheck, stGeorgePoint, "miles");

    return (distanceToWhiteHall <= 1 || stGeorgeDistance <= 1);
}

export function getSiFerryDelayRouteObj(latitude: number, longitude: number, 
        lastExitTime: string, lastExitHour: number, lastExitMinute: number){
    const pointToCheck = tfpoint([longitude, latitude]);
    const distanceToWhiteHall = tfdistance(pointToCheck, whiteHallPoint,"miles");
    const stGeorgeDistance = tfdistance(pointToCheck, stGeorgePoint, "miles");

    let fromStation = StGeorgeTerminal;
    let toTerminal = WhiteHallTerminal;

    if(distanceToWhiteHall < stGeorgeDistance){
        fromStation = WhiteHallTerminal;
        toTerminal = StGeorgeTerminal;
    }

    const delayRouteObj: IDelayRouteUi = {
        routeId: "SIFerry",
        routeType: TransitTypeEnum.SIFerry,
        direction: null,
        entryPoint: fromStation.stopId,
        exitPoint: toTerminal.stopId,
        entryTime: lastExitTime,
        exitTime: lastExitTime,
        legNumber: 0,
        routeName: "SI Ferry",
        entryPointName: fromStation.stopName,
        exitPointName: toTerminal.stopName,
        entryPointLat: fromStation.latitude,
        entryPointLon: fromStation.longitude,
        exitPointLat: toTerminal.latitude,
        exitPointLon: toTerminal.longitude,
        exitHour: lastExitHour,
        exitMinute: lastExitMinute,
        gotFirstSched: true,
    };
    return delayRouteObj;
}