import React from "react";
import { IBusTrainStop } from "../../../../apis/interfaces/IBusTrainStop";
import { StopCardList } from "./StopCardList";
import { TransitTypeEnum } from "../../../../apis/enums/TransitTypeEnum";


export interface IExtraStopSelectorProps {
    stopList: IBusTrainStop[];
    alreadySelectedStop?: IBusTrainStop;
    stopCardBorderColor: string;
    updateSelectedStop: (selStop: IBusTrainStop)=> void;
    transitType: TransitTypeEnum;
}

interface IExtraStopSelectorState {
}

export class ExtraStopSelector extends React.Component<IExtraStopSelectorProps, IExtraStopSelectorState> {

    public render() {
        return (
            <div >
                <hr></hr>
                <StopCardList stopList={this.getStopList()}
                    updateSelectedStop={this.updateSelectedStop}
                    stopCardBorderColor = {this.props.stopCardBorderColor}
                    alreadySelectedStop={this.props.alreadySelectedStop}
                    transitType = {this.props.transitType}
                />
            </div>
        )
    }

    private getStopList() {
        return this.props.stopList;
    }

    private updateSelectedStop = (selStop: IBusTrainStop) => {
        this.props.updateSelectedStop(selStop);
    }
}