import React from "react";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import * as TrainRoutesApi from "../../../../apis/TrainRoutesApi";
import { CircleSpinner } from "../../../common/CircleSpinner";
import { ErrorAlert } from "../../../common/ErrorAlert";
import { TrainRouteIcon } from "./TrainRouteIcon";
import { IDelayRouteUi } from "../../interfaces/IDelayRouteUi";
import * as ApiErrorHandler from "../../../../apis/ApiErrorHandler";

export interface ITrainRouteSelectorState {
    trainRouteGetStarted: boolean;
    trainRouteGetSuccess: boolean;
    trainRouteGetFailed: boolean;
    trainRoutes: IBusTrainRoute[];
    errorMessage: string;
}

export interface ITrainRouteSelectorProps {
    serviceDateStr: string;
    updateSelectedTrainRoute: (selectedRoute: IBusTrainRoute) => void;
    lastDelayRouteRec: IDelayRouteUi | null;
}


export class TrainRouteSelector extends React.Component<ITrainRouteSelectorProps, ITrainRouteSelectorState> {
    constructor(props: any) {
        super(props);
        this.state = {
            trainRouteGetStarted: true,
            trainRouteGetSuccess: false,
            trainRouteGetFailed: false,
            trainRoutes: [],
            errorMessage: "",
        }
    }

    public async componentDidMount() {
        try {
            this.setState({ trainRouteGetStarted: true, trainRouteGetSuccess: false, trainRouteGetFailed: false, trainRoutes: [], errorMessage: "" });
            let trainRoutes: IBusTrainRoute[] = [];
            if (this.props.lastDelayRouteRec) {
                trainRoutes = await TrainRoutesApi.GetNearbySubwayRoutes(this.props.serviceDateStr,
                    this.props.lastDelayRouteRec.exitPointLat, this.props.lastDelayRouteRec.exitPointLon);
            } else {
                trainRoutes = await TrainRoutesApi.GetSubwayRoutes(this.props.serviceDateStr);
            }

            this.setState({ trainRouteGetStarted: false, trainRouteGetSuccess: true, trainRouteGetFailed: false, trainRoutes: trainRoutes, errorMessage: "" });
        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ trainRouteGetStarted: false, trainRouteGetSuccess: false, trainRouteGetFailed: true, trainRoutes: [], errorMessage});
        }
    }

    public render() {
        if (this.state.trainRouteGetStarted) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.trainRouteGetSuccess) {
            return (
                <div>
                    {this.props.lastDelayRouteRec && <small
                        className="form-text text-muted">Showing nearby subway routes based on the last stop selected </small>}
                    {this.renderRouteIconList()}
                </div>
            )
        } else {
            return (
                <ErrorAlert alertText={this.state.errorMessage} />
            )
        }

    }

    private renderRouteIconList = () => {
        const routeIconList = this.state.trainRoutes
            .sort((a, b) => {
                let orderA = a.sortOrder;
                let orderB = b.sortOrder;
                // Api is not returning sort order for FX. Adding override
                if (a.shortName === "FX" && a.sortOrder === 0) {
                    orderA = 16.5;
                }
                if (b.shortName === "FX" && b.sortOrder === 0) {
                    orderB = 16.5;
                }

                if (orderA < orderB) {
                    return -1;
                }
                if (orderA > orderB) {
                    return 1;
                }
                return 0;
            })
            .map((rec, index) => {
                return (
                    <div className="col-sm-4" key={index}>
                        <TrainRouteIcon trainRouteObj={rec}
                            updateSelectedTrainRoute={this.props.updateSelectedTrainRoute}
                        />
                    </div>
                );
            })

        return (
            <div className="row">
                {routeIconList}
            </div>
        )
    }
}