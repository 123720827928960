import React from "react";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import * as BusRoutesApi from "../../../../apis/BusRoutesApi";
import { CircleSpinner } from "../../../common/CircleSpinner";
import { ErrorAlert } from "../../../common/ErrorAlert";
import { BusRouteCardList } from "./BusRouteCardList";
import { IDelayRouteUi } from "../../interfaces/IDelayRouteUi";
import * as ApiErrorHandler from "../../../../apis/ApiErrorHandler";
import { ApiCallStatusEnum } from "../../../common/enums/ApiCallStatusEnum";

export interface IBusRouteSelectorState {
    apiCallStatus: ApiCallStatusEnum;
    busRoutes: IBusTrainRoute[];
    errorMessage: string;
    filterText: "",
    filteredRouteList: IBusTrainRoute[]
}

export interface IBusRouteSelectorProps {
    serviceDateStr: string;
    updateSelectedBusRoute: (selectedRoute: IBusTrainRoute) => void;
    lastDelayRouteRec: IDelayRouteUi | null;
}

export class BusRouteSelector extends React.Component<IBusRouteSelectorProps, IBusRouteSelectorState> {

    constructor(props: any) {
        super(props);

        this.state = {
            apiCallStatus: ApiCallStatusEnum.Started,
            busRoutes: [],
            errorMessage: "",
            filterText: "",
            filteredRouteList: []
        }
    }

    public async componentDidMount() {
        try {
            this.setState({apiCallStatus: ApiCallStatusEnum.Started, busRoutes: [], errorMessage: "" });
            let busRoutes: IBusTrainRoute[] = [];
            if (this.props.lastDelayRouteRec) {
                busRoutes = await BusRoutesApi.GetNearByBusRoutes(this.props.serviceDateStr, this.props.lastDelayRouteRec.exitPointLon, this.props.lastDelayRouteRec.exitPointLat);
            } else {
                busRoutes = await BusRoutesApi.GetBusRoutes(this.props.serviceDateStr);
            }

            this.setState({ apiCallStatus:ApiCallStatusEnum.Success, busRoutes: busRoutes, errorMessage: "" });

        } catch (err) {
            const errorMessage = ApiErrorHandler.GetApiErrorMessage(err);
            this.setState({ apiCallStatus: ApiCallStatusEnum.Failed, busRoutes: [], errorMessage});
        }
    }

    public render() {
        if (this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        } else if (this.state.apiCallStatus === ApiCallStatusEnum.Success && this.state.busRoutes) {
            return (
                <div>
                    <input className="form-control"
                        type="text" placeholder="Filter Bus Route List"
                        value={this.state.filterText} onChange={this.handleFilterChange} />
                    {this.props.lastDelayRouteRec &&  <small  
                        className="form-text text-muted">Showing nearby bus routes based on the last stop selected </small>}
                    <BusRouteCardList busRouteList={this.getBusRouteList()} updateSelectedBusRoute={this.props.updateSelectedBusRoute} />
                </div>
            )
        } else {
            return (
                <ErrorAlert alertText={this.state.errorMessage} />
            )
        }
    }

    private handleFilterChange = (event: any) => {
        this.setState({ filterText: event.target.value });
    }


    private getBusRouteList() {
        if (this.state.filterText) {
            const filteredRouteList = this.state.busRoutes.filter((x) => {
                const searchableText = x.shortName; // keeping the searchable text to short name
                return (searchableText.toUpperCase().indexOf(this.state.filterText.toUpperCase()) >= 0);
            });

            return filteredRouteList;
        } else {
            return this.state.busRoutes;
        }

    }

}