import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

export interface IServiceDatePickerControlProps {
    label: string;
    value: moment.Moment | undefined;
    changeHandler: (value: any) => void;
    isValid: boolean;
    validationMessage?: string;
    disablePicker?: boolean;
    disabledMessage?: string;
    selectablePastDays: number;
}

export interface IServiceDatePickerControlState {
    selectedValue: moment.Moment | string | undefined;
}

export class ServiceDatePickerControl extends React.Component<IServiceDatePickerControlProps, IServiceDatePickerControlState> {

    constructor(props: IServiceDatePickerControlProps) {
        super(props);
        this.state = {
            selectedValue: undefined
        }
    }

    public componentDidMount() {
        this.setState((state, props) => {
            if (props.value) {
                return { selectedValue: moment(props.value) }
            } else {
                return { selectedValue: undefined }
            }
        })
    }

    public render() {
        return (
            <div className="form-group">
                <label >{this.props.label}</label>
                <Datetime
                    value={this.state.selectedValue}
                    onChange={this.handleDateChange}
                    onBlur={this.focousOut}
                    inputProps={{ className: this.getInputClassName(), disabled: this.props.disablePicker || false }}
                    locale='en-US'
                    dateFormat={true}
                    timeFormat={false}
                    isValidDate={this.isValidDate}
                
                />
                {this.props.validationMessage && !this.props.isValid &&
                    <div style={{ marginTop: "0.25rem", fontSize: "80%", color: "#dc3545" }} > {this.props.validationMessage} </div>}
                {this.props.disablePicker && this.props.disabledMessage && <small id="emailHelp" className="form-text text-muted">{this.props.disabledMessage || ""}</small> }
            </div>
        )
    }

    private focousOut = (value: any) => {
        if (typeof value === "string") {
            this.setState({ selectedValue: moment(value) });
        }
    }

    // Datetime control returns string if the date entered by user is not valid 
    private handleDateChange = (dateVal: moment.Moment | string) => {
        this.setState({ selectedValue: dateVal })
        if (typeof dateVal !== "string") {
            this.props.changeHandler(dateVal)
        } else {
            this.props.changeHandler(moment(dateVal));
        }
    }

    private getInputClassName = () => {
        if (this.props.isValid) {
            return "form-control";
        } else {
            return "form-control is-invalid";
        }
    }

    private isValidDate = (current: moment.Moment) => {
        if(this.props.selectablePastDays){
            const firstSelectableDay = moment().subtract(this.props.selectablePastDays, "days");
            const today = moment();
            return current.isAfter( firstSelectableDay ) && current.isBefore(today);
        }
        return true;
    }

}