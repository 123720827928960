import React from "react";
import { IBusTrainRoute } from "../../../../apis/interfaces/IBusTrainRoute";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IBusRouteCardProps {
    busRouteObj: IBusTrainRoute;
    updateSelectedBusRoute: (selectedRoute: IBusTrainRoute) => void;
}

export class BusRouteCard extends React.Component<IBusRouteCardProps, {}> {

    public render() {
        return (
            <div className="busstop_route_div_button mt-2"
                onClick={(event) => { this.props.updateSelectedBusRoute(this.props.busRouteObj) }}
            >
                <div className="row no-gutters">
                    <div className="col-sm-2" >
                        <span className="badge badge-primary" style={{ fontSize: 12 }}>
                            {this.props.busRouteObj.shortName}
                        </span>

                    </div>
                    <div className="col-sm-9">
                        {this.props.busRouteObj.longName}
                    </div>
                    <div className="col-sm-1">
                        <span className="float-right"><FontAwesomeIcon size="1x" icon={faAngleRight} /></span>
                    </div>

                </div>

            </div>
        )
    }
}