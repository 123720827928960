import React, { Component } from 'react';
import { NavbarComponent } from '../Navbar/NavbarComponent';
import { RequestForm } from '../RequestForm/RequestForm';
import { isIE } from 'react-device-detect'

export class HomePageComponent extends Component {

    public render() {
        const bannerMessage = process.env.REACT_APP_BannerMessage;
        if (isIE) {
            return <div className="alert alert-warning mt-2" role="alert"> Warning: You are using an unsupported browser. This application may not work as expected. Please use a recent version of Chrome, Edge or Firefox.</div>
        }
        return (
            <div>
                <NavbarComponent />
                <div className="container">
                    <div className="dm-pageHeader">
                        <h1 className="display-4 dm-font-header-bold">Subway and Bus Delay Verification</h1>
                    </div>
                    {bannerMessage &&
                        <div className="alert alert-warning mt-2" role="alert">
                            {bannerMessage}
                        </div>}
                    <RequestForm />

                </div>
            </div>
        )

    }
}